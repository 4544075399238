<template>
      <!-- prettier-ignore -->
    <div>
        <div class="main-div">
      <Nav color="white--text"/>
      <div class="inner-div">
        <v-row align="center" justify="center" class="mx-0">
          <v-col  cols="11" md="8" class="text-center pa-0 pa-sm-3">
            <div class="pa-5 pa-md-0 white--text banner-text text-center ">
              <div @click="toggleText(1)">
                <p v-show="visibleText === 0" class="stellage-regular mb-0 pointer">
                  Little things for her.
                </p>
              </div>
               <div @click="toggleText(2)" class="d-flex align-center justify-center">
                <template >
                  <img :src="logo" class="logo-img mr-4 pointer"  v-show="visibleText === 1"/>
                  <p class="stellage-constructed text-center mb-0 pointer"  v-show="visibleText === 1">LTFH</p>
                </template>
              </div>
  
              <span @click="toggleText(0)" class="pointer">
                <p class="stellage-constructed text-center mb-0" v-show="visibleText === 2">
                  OPENING SOON
                </p>
              </span> 
            </div>
            
          </v-col>
        </v-row>
        <div class="footer-text white--text">
              <p class="text-center ">
              Luxury women fashion<br />
              accessory store.
            </p>
            </div>           
      </div>
    </div>
    </div>
   
  </template>
  
  <script>
  import logo from "@/assets/logo.svg";
  import Nav from "@/components/Nav.vue";
  export default {
    components: {
      Nav
    }, 
    data() {
      return {
        visibleText: 0,
        logo: logo,
      };
    },
    methods: {
      toggleText(index) {
        this.visibleText = index;
      },
    },
  };
  </script>
  
  <style scoped>
  .main-div {
    background-image: url("../assets/bg-img.svg");
    /* background-image: url("https://res.cloudinary.com/kemisolaola/image/upload/v1697453537/background_fsspxk.svg"); */
    background-size: cover;
    background-position:  50% 35%;
    height: 100vh;
    
  }
  .nav {
    height: 100px;
  }
  .card {
    cursor: pointer;
  }
  
  </style>
  