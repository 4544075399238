<template>
    <!-- prettier-ignore -->
  <div>
      <div class="main-div">
    <Nav color="black--text" page="about"/>
    <div class="inner-div">
      <v-row align-md="center" justify-sm="center" class="mx-0">
        <v-col cols="12"  sm="8" md="6" class="text-center pt-0 pl-0">
          <div class="pa-3 pa-sm-4 pa-md-0 black--text banner-text text-center ">
            <div >
                <div class="d-flex ">
                  <router-link to="/">
                    <img src="../assets/black-logo.svg" class="about-logo ml-md-n5"/>
                  </router-link>
                </div>
              
            </div>

        </div>
          
        </v-col>
         <v-col cols="12" sm="8" md="7" class="text-md-center pa-0 pa-sm-3 pa-md-0 mt-10">
          <div class="pl-3 pr-6 black--text  text-md-center ">
            <div >
             
              <div class="">
                <p class="stellage-regular banner-text mx-5 mx-sm-0 mb-0">
                Little things for her.
              </p>
                <p class="about-inner-text  mt-md-13 text-left pl-md-3 pr-2 ml-md-2 mr-5 mr-md-0">
                Luxury women fashion accessory store. Everything you need to
complement your outfit- Shoes, bags, jewelries and lots more.
<br><br class="hidden-sm-and-up">    
                </p>
                <p class="about-inner-text text-left pl-md-3 pr-2 ml-md-2 mr-16 mr-md-0">

We are building our online store, For now, check us out on<span class="font-weight-bold"><a href="https://www.instagram.com/littlethings4her_/?hl=en" class="black--text text-decoration-underline"> Instagram </a> </span>              </p>
            
              </div>
           </div>

        </div>
          
        </v-col>
      </v-row>
         
    </div>
  </div>
</div>
 
</template>

<script>
import logo from "@/assets/logo.svg";
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav
  }, 
  data() {
    return {
      visibleText: 0,
      logo: logo,
    };
  },
};
</script>

<style scoped>
.main-div {
  height: 100vh;
  
}
.nav {
  height: 100px;
}

.card {
  cursor: pointer;
}

</style>
