<template>
  <div>
    <v-navigation-drawer
      app
      temporary
      v-model="drawer"
      right
      class="nav-drawer"
    >
      <div class="d-flex justify-space-between pa-5 nav-drawer-text">
        <p>Your bag is empty</p>
        <p class="text-decoration-underline pointer" @click="drawer = !drawer">
          Close
        </p>
      </div>
      <!-- Add the content and styles for your navigation drawer here -->
    </v-navigation-drawer>
    <nav class="nav d-flex justify-space-between py-5 px-4 px-md-7">
      <div class="mobile-menu hidden-md-and-up">
        <p class="about-menu pointer" :class="color">
          <span class="hidden-md-up" @click="showAbout = !showAbout">{{ showAbout ? '(Close)' : 'Menu' }}</span>
        </p>
        <router-link to="/about" :class="color" v-if="showAbout" class="about-menu-mobile">
          <p class="hidden-md-and-up ">About</p></router-link
        >
      </div>
      <router-link to="/about" :class="color">
        <p class="about-menu pointer hidden-sm-and-down" >
          About
        </p>
      </router-link>
      <img
        @click="drawer = true"
        v-if="page === 'about'"
        src="../assets/bag-black-icon.svg"
        alt="bag"
        class="pointer"
        width="25"
        height="30"
      />
      <img
        @click="drawer = true"
        v-else
        src="../assets/bag-icon.svg"
        alt="bag"
        class="pointer"
        width="25"
        height="30"
      />
    </nav>
  </div>
</template>

<script>
export default {
  props: ["color", "page"],
  data() {
    return {
      drawer: false,
      showAbout: false,
    };
  },
};
</script>
